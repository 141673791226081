import Swiper, { Navigation, Pagination } from "swiper";

export default function sliders() {

  (function simpleSlider() {
    // Install modules
    Swiper.use([Navigation, Pagination]);
    const swiper = new Swiper(".hero__slider", {
      slidesPerView: 1,
      watchOverflow: true,
      navigation: {
        nextEl: ".hero__slide__button-next",
        prevEl: ".hero__slide__button-prev",
      },
      // breakpoints: {
      //   768: {
      //     slidesPerView: "auto",
      //     spaceBetween: 70,
      //   },
      // },
    });
  })();


}

import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/scss/'

import sliders from './sliders';
// import modals from './modals';
import controls from './controls';
import map from './map';
// import animation from './animation';

// import checkUserSystem from "./lib/checkUserSystem";


// const collapseElementList = [].slice.call(document.querySelectorAll('.collapse'))
// const collapseList = collapseElementList.map(function (collapseEl) {
//   return new Collapse(collapseEl)
// })


global.ww = window.innerWidth;
global.wh = window.innerHeight;

$(document).ready(() => {

  // console.log(Collapse); // eslint-disable-line


    document.body.className += ' ready';
    global.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    controls()
    sliders()
    map()
    // modals()
    // animation()

    const allResponsives = () => {
        global.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        // console.log('isMobile:', global.isMobile);
    };
    allResponsives();

    window.addEventListener('resize', () => {
        global.ww = window.innerWidth;
        global.wh = window.innerHeight;
        allResponsives();
    });
});
